import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Icon } from "../atoms/Icon";
import { CSSProperties } from "react";

interface Props {
    type: 'dark' | 'full light' | 'light' | 'confirm' | 'primary' | 'secondary' | 'terciary' | 'icon' | 'action'
    icon?: string
    iconElement?: JSX.Element
    text?: string
    disabled?: boolean
    fullWidth?: boolean
    style?: CSSProperties
    onClick: () => void
}

export const GenericButton = styled.button`
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;
    transition: all .3s cubic-bezier(.19,1,.22,1);
    color: white;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    z-index: 1;
    display: 'flex'; 
    align-items: 'center'; 
    justify-content: 'center';
    box-shadow: 0px 0px 6px #00000025;

    &:hover {
        box-shadow: 0px 3px 6px #00000025;
        transform: scale(1.01);
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: ${colors.grey_normal};
        border: 1px solid ${colors.border}50;
        color: ${colors.primary_dark};
        font-weight: normal;
        box-shadow: unset;


        &:hover {
            box-shadow: unset;
            transform: unset;
        }
    }
`

const PrimaryButton = styled((GenericButton))`
    background-color: ${colors.primary};
    display: 'flex'; 
    align-items: 'center'; 
    justify-content: 'center';

    &:hover:enabled {
        background-color: ${colors.primary_light};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        cursor: pointer;
    }
`

const ConfirmButton = styled((GenericButton))`
    background-color: ${colors.ok};
    display: 'flex'; 
    align-items: 'center'; 
    justify-content: 'center';

    &:hover:enabled {
        background-color: ${colors.primary_light};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        cursor: pointer;
    }
`

const DarkButton = styled((GenericButton))`
    height: fit-content !important;
    font-size: 20px !important;
    background-color: ${colors.dark};
    color: ${colors.light} !important;
    border: 1px solid ${colors.dark};
    display: 'flex'; 
    align-items: 'center'; 
    justify-content: 'center';

    &:hover:enabled {
        background-color: ${colors.primary_light};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        cursor: pointer;
    }

    @media ${device.md} {
        font-size: 16px !important;
    }
`

const LightButton = styled((GenericButton))`
    height: fit-content !important;
    font-size: 20px !important;
    color: ${colors.light} !important;
    border: 1px solid ${colors.light};
    display: 'flex'; 
    align-items: 'center'; 
    justify-content: 'center';

    &:hover:enabled {
        background-color: ${colors.primary_light};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        cursor: pointer;
    }

    @media ${device.md} {
        font-size: 16px !important;
    }
`

const FullLightButton = styled((GenericButton))`
    height: fit-content !important;
    font-size: 20px !important;
    background-color: ${colors.light};
    color: ${colors.dark} !important;
    border: 1px solid ${colors.light};
    display: 'flex'; 
    align-items: 'center'; 
    justify-content: 'center';

    &:hover:enabled {
        background-color: ${colors.primary_light};
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        cursor: pointer;
    }

    @media ${device.md} {
        font-size: 16px !important;
    }
`


const SecondaryButton = styled(GenericButton)`
    background-color: ${colors.grey_light};
    border: 1px solid ${colors.border};
    color: ${colors.primary_dark};
    font-weight: normal;

    &:hover:enabled {
        background-color: darken($color: ${colors.grey_light}, $amount: 15);
        box-shadow: 0px 4px 10px #00000026;
        cursor: pointer;
    }
`

const TerciaryButton = styled.button`
    background-color: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    color: ${colors.primary_light};
    font-weight: bold;
    z-index: 1;
    font-size: 16px;
    

    @media ${device.sm} {
        font-size: 14px;
    }

    &:hover {
        color: ${colors.primary};
        border-bottom: 1px solid ${colors.primary};
        cursor: pointer;
    }
`

const IconButton = styled.button`
    background-color: transparent;
    border: none;
    border: 1px solid transparent;
    color: ${colors.primary_light};
    font-weight: bold;
    z-index: 1;
    font-size: 16px;

    @media ${device.sm} {
        font-size: 14px;
    }

    &:hover {
        cursor: pointer;
        border: ${colors.info} 1px solid;
        box-shadow: 0px 4px 6px #00000026;

        @media ${device.lg} {
            border: 1px solid  transparent;
            box-shadow: none;
        } 
    }
`

const ActionButton = styled.button` 
    height: 30px;
    width: 30px;
    background-color: ${colors.info_light};
    border: 1px solid ${colors.info};
    border-radius: 50px;
    font-weight: bold;
    z-index: 1;
    font-size: 16px;

    @media ${device.sm} {
        font-size: 14px;
    }

    &:hover {
        cursor: pointer;
        border: ${colors.info} 1px solid;
        box-shadow: 0px 4px 6px #00000026;

        @media ${device.lg} {
            border: 1px solid transparent;
            box-shadow: none;
        } 
    }
`



export const Button = ({ type, text, icon, iconElement, disabled, fullWidth, style, onClick }: Props) => {

    const setButton = () => {

        switch (type) {
            case 'confirm':
                return (
                    <ConfirmButton
                        disabled={disabled}
                        style={{
                            width: fullWidth ? '100%' : 'auto', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', ...style
                        }}
                        onClick={() => onClick()}
                    >

                        {iconElement ?? iconElement}

                        {icon ? (
                            <>
                                <Icon
                                    size="S"
                                    src={icon}
                                />
                                &nbsp;&nbsp;{text}
                            </>) : text}

                    </ConfirmButton>
                )

            case 'dark':
                return (
                    <DarkButton
                        disabled={disabled}
                        style={{
                            width: fullWidth ? '100%' : 'auto', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', ...style
                        }}
                        onClick={() => onClick()}
                    >

                        {iconElement ?? iconElement}

                        {icon ? (
                            <>
                                <Icon
                                    size="S"
                                    src={icon}
                                />
                                &nbsp;&nbsp;{text}
                            </>) : text}

                    </DarkButton>
                )

            case 'light':
                return (
                    <LightButton
                        disabled={disabled}
                        style={{
                            width: fullWidth ? '100%' : 'auto', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', ...style
                        }}
                        onClick={() => onClick()}
                    >

                        {iconElement ?? iconElement}

                        {icon ? (
                            <>
                                <Icon
                                    size="S"
                                    src={icon}
                                />
                                &nbsp;&nbsp;{text}
                            </>) : text}

                    </LightButton>
                )

            case 'full light':
                return (
                    <FullLightButton
                        disabled={disabled}
                        style={{
                            width: fullWidth ? '100%' : 'auto', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', ...style
                        }}
                        onClick={() => onClick()}
                    >

                        {iconElement ?? iconElement}

                        {icon ? (
                            <>
                                <Icon
                                    size="S"
                                    src={icon}
                                />
                                &nbsp;&nbsp;{text}
                            </>) : text}

                    </FullLightButton>
                )

            case 'primary':
                return (
                    <PrimaryButton
                        disabled={disabled}
                        style={{
                            width: fullWidth ? '100%' : 'auto', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', ...style
                        }}
                        onClick={() => onClick()}
                    >

                        {iconElement ?? iconElement}

                        {icon ? (
                            <>
                                <Icon
                                    size="S"
                                    src={icon}
                                />
                                &nbsp;&nbsp;{text}
                            </>) : text}

                    </PrimaryButton>
                )
            case 'secondary':
                return (
                    <SecondaryButton
                        disabled={disabled}
                        style={{
                            width: fullWidth ? '100%' : 'auto', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', ...style
                        }}
                        onClick={() => onClick()}

                    >

                        {iconElement ?? iconElement}

                        {icon ? (
                            <>
                                <Icon
                                    size="S"
                                    src={icon}
                                />
                                &nbsp;&nbsp;{text}
                            </>) : text}

                    </SecondaryButton>
                )
            case 'terciary':
                return (
                    <TerciaryButton
                        onClick={() => onClick()}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', ...style
                        }}
                    >

                        {iconElement ?? iconElement}

                        {icon ? (
                            <>
                                <Icon
                                    size="S"
                                    src={icon}
                                />
                                &nbsp;&nbsp;{text}
                            </>) : text}

                    </TerciaryButton>
                )

            case 'icon':
                return (
                    <IconButton
                        onClick={() => onClick()}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', ...style
                        }}
                    >

                        {iconElement ?? iconElement}

                        {icon ? (
                            <>
                                <Icon
                                    size="M"
                                    src={icon}
                                />
                            </>) : null}

                    </IconButton>
                )

            case 'action':
                return (
                    <ActionButton
                        onClick={() => onClick()}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', ...style
                        }}
                    >

                        {iconElement ?? iconElement}

                        {icon ? (
                            <>
                                <Icon
                                    size="M"
                                    src={icon}
                                    style={{ width: 14 }}
                                />
                            </>) : null}

                    </ActionButton>
                )
        }
    }

    return setButton();
}
