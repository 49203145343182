
const handleClickGoSection = (id: string, trasition: 'smooth' | 'auto') => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: trasition });
    }
}

export const useAnimations = () => {

    // HOME

    const handleClickGoHome = () => { handleClickGoSection("home", "smooth") }

    // PRODUCTS

    const handleClickGoProducts = () => { handleClickGoSection("products", "smooth") }


    // CONTACT

    const handleClickGoContact = () => { handleClickGoSection("contact", "smooth") }

    return {
        handleClickGoHome,
        handleClickGoProducts,
        handleClickGoContact,
    }

}