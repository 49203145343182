import { memo } from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  height: 200px;
  margin-top: 5px;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Chase = styled.div`
  width: 150px;
  height: 50px;
  position: relative;
  z-index: 1;
`

const Dot = styled.div`

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 .5s alternate infinite ease;

    &:nth-child(2) {   
        left: 45%;
        animation-delay: .2s; 
    }
    &:nth-child(3) { 
        left: auto;
        right: 15%;
        animation-delay: .3s;
    }
  
`

const Shadow = styled.div`

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}

  width: 15px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 .5s alternate infinite ease;

  &:nth-child(4) {   
    left: 45%;
    animation-delay: .2s
  }
  
  &:nth-child(5) { 
    left: auto;
    right: 15%;
    animation-delay: .3s;
  }
`

const Loading = () => {

  return (

    <Container>

      <Chase>

        <Dot></Dot>

        <Dot></Dot>

        <Dot></Dot>

        <Shadow></Shadow>

        <Shadow></Shadow>

        <Shadow></Shadow>

      </Chase>

    </Container>

  )
}

const LoadingComponent = memo(Loading);

export default LoadingComponent;