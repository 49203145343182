import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useDispatch, useSelector } from "react-redux"
import { getFullDate, } from "../utils/Utilities"
import { ChangeEvent, useRef, useState } from "react"
import { showMixinToast } from "../utils/Alerts"
import Rights from "./Rights"
import { finishLoading } from "../actions/ui"
import { Input } from "./molecules/Input"
import { Button } from "./molecules/Button"

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    background-color: #111;

    @keyframes hide {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    &.active {
        animation: hide 1s ease 2s;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-bottom: 15vh;
    margin-top: 10vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;

    h1 {
        font-size: 28px;
        text-transform: uppercase;
        color: #111;
        letter-spacing: 10px;
        text-align: center;
        font-weight: 500;

        @media ${device.md} {
            font-size: 22px;
        }

        @media ${device.sm} {
            font-size: 18px;
        }

        @media ${device.xs} {
            font-size: 16px;
        }

    }

`

const RightsDiv = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0px;
`

const VideoContainer = styled.img`
    width: 50vw;
    max-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    object-fit: contain !important;

    @media ${device.lg} {
        margin-top: 145px;
    }

    @media ${device.md} {
        margin-top: 125px;
    }

    @media ${device.sm} {
        margin-top: 105px;
    }

`

interface Props {
    showInProgress: boolean;
    preloader: boolean;
    passwordOk: boolean;
    setPasswordOk: React.Dispatch<React.SetStateAction<boolean>>
}

export const Building = ({ showInProgress, preloader, passwordOk, setPasswordOk }: Props) => {

    const [introImages] = useState(`${process.env.REACT_APP_CDN}/boyy.webp`);

    const videoRef = useRef<HTMLVideoElement>(null);

    const { loading } = useSelector((state: any) => state.ui);

    const dispatch = useDispatch();

    const [form, setForm] = useState('');

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            handleConfirm()
        }
    };

    const handleConfirm = () => {

        if (passwordOk === false && form.trim() !== '' && form.trim() === process.env.REACT_APP_IN_PROGRESS_PASSWORD) {

            showMixinToast('Acceso permitido', 'success', 'bottom');

            setPasswordOk(true);

            localStorage.setItem('passwordExpire', (parseInt(getFullDate()) + 10000000).toString().padEnd(17, '0'));

            dispatch(finishLoading());

        } else {
            showMixinToast('contraseña incorrecta', 'error', 'bottom');
        }

    }

    return (

        <Container className={!loading ? 'active' : ''}>

            <ContentContainer>

                {
                    showInProgress && (

                        <TitleContainer>

                            <h1>Pagina en proceso</h1>

                        </TitleContainer>

                    )
                }

                <VideoContainer
                    src={introImages}
                    alt="logo"
                />

                {
                    showInProgress && (

                        <>

                            <Input
                                type="text"
                                label="Clave"
                                placeholder="Ingrese la clave..."
                                value={form}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setForm(e.target.value)}
                                onKeyDown={handleKeyPress}
                                style={{ maxWidth: 250 }}
                                theme="dark"
                                labelTheme="dark"
                            />
                            <Button type="confirm" text="Ingresar" style={{ marginTop: 20 }} onClick={() => handleConfirm()} />

                        </>

                    )
                }
            </ContentContainer>

            {
                showInProgress && (

                    <RightsDiv>
                        <Rights brand="FAL Herramientas" backgroundColor="#EEE" color="#111" />
                    </RightsDiv>

                )
            }

        </Container >

    )
}

export default Building;