import Introduction from "../components/Introduction"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers";
import ScreenContainer from "../components/ScreenContainer"
import { getFullDate } from "../utils/Utilities";
import Rights from "../components/Rights";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import { Catalog } from "../components/organisms/Catalog";
import About from "../components/About";
import { Footer } from "../components/Footer";
import Building from "../components/Building";
import ScrollToTop from "../components/ScrollToTop";
import { Offer } from "../components/organisms/Offer";

export const HomeScreen = () => {

    const [showPreloader, setShowPreloader] = useState(true);

    const [passwordOk, setPasswordOk] = useState((localStorage.getItem('passwordExpire') !== undefined && localStorage.getItem('passwordExpire') !== null && parseInt(getFullDate()) < parseInt(localStorage.getItem('passwordExpire')!)) ?? false);

    const location = useLocation()

    const { loading } = useSelector((state: any) => state.ui);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const preloader = () => {

        if (!loading) {

            setTimeout(() => {
                setShowPreloader(false);
            }, 2900);

        }

    };

    useEffect(() => {

        preloader()

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname, loading])

    if (process.env.REACT_APP_IN_PROGRESS === '1' && !passwordOk) return <Building showInProgress preloader={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />


    return (

        <ScreenContainer>

            {showPreloader && <Building showInProgress={false} preloader={true} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}

            <Header />

            <Introduction />

            <Offer />

            <Catalog />

            <About />

            <Footer />

            <Rights brand="FAL Herramientas" backgroundColor="#000000" />

        </ScreenContainer>

    )
}
