import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { uiReducer } from '../reducers/uiReducer';
import { falHerramientasReducer } from '../reducers/falHerramientasReducer';

const reducers = combineReducers({
    ui: uiReducer,
    fallherramientas: falHerramientasReducer,
});

export const store = createStore(
    reducers,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
); 