import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"

const SocialContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin-bottom: 50px;
    margin-left: -10px;
    
    & > h2 {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        align-self: start;
        margin-left: 10px;
        margin-bottom: 30px;

        @media ${device.md} {
            margin-left: 0px;
        }
    }

    @media ${device.sm} {
        margin-bottom: 30px;
    }

`

const SocialButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;

    @media ${device.md} {
        margin-left: 30px;
    }

    @media ${device.sm} {
        margin-left: 25px;
    }

    @media ${device.xs} {
        margin-left: unset;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
`

const SocialButton = styled.a`
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;

    &:nth-child(1) {
        border-right: solid 1px #d9d9d9;
        padding-right: 30px;

        @media ${device.sm} {
            padding-right: 15px;
        }

        @media ${device.xs} {
            border-right: unset;
            padding-right: unset;
        }


    }

    @media ${device.sm} {
        width: fit-content;
        padding-left: 15px;
        padding-right: 15px;
    }

    
    @media ${device.sm} {
        width: fit-content;
        padding-left: unset;
        padding-right: unset;
        align-self: center;
        justify-self: center;
    }

    & > img {
        height: 16px;
        width: 16px;

        @media ${device.xs} {
            height: 14px;
            width: 14px;
        }
    }   

    & > h3 {
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.9px;
    text-transform: uppercase;
    margin-left: 10px;

    @media ${device.sm} {
        font-size: 12px;
        letter-spacing: 1px;

    }

    @media ${device.xs} {
        font-size: 10px;
        letter-spacing: 1px;
    }
}
`


export const Social = () => {

    const { handleLogEvent } = useLogEvent();

    return (

        <SocialContainer className='utils__fade-in-top'>

            <SocialButtonsContainer>

                <SocialButton
                    href="https://www.facebook.com/falherramientas/"
                    target='_blank'
                    rel="noreferrer"
                    onClick={() => {
                        handleLogEvent('Click_boton_facebook');
                    }}
                >

                    <img
                        src={require('../images/facebook.webp')}
                        alt='Logo 1'
                    />

                    <h3>Facebook</h3>

                </SocialButton>

                <SocialButton
                    href="mailto:falherramientas@gmail.com"
                    target='_blank'
                    rel="noreferrer"
                    onClick={() => {
                        handleLogEvent('Click_boton_correo');
                    }}
                >

                    <img
                        src={require('../images/correo-electronico.webp')}
                        alt='Logo 2'
                    />

                    <h3>Correo</h3>

                </SocialButton>

            </SocialButtonsContainer>

        </SocialContainer>

    )
}

export default Social;