import { ProductProps } from "../interfaces/ProductInterfaces";

export const getFullDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = formatTwoNumbers(date.getDate());
    const hours = formatTwoNumbers(date.getHours());
    const minutes = formatTwoNumbers(date.getMinutes());
    const seconds = formatTwoNumbers(date.getSeconds());
    const miliseconds = fillWithCeros(date.getMilliseconds().toString(), 3);

    fullDate = `${year}${month}${day}${hours}${minutes}${seconds}${miliseconds}`;

    return fullDate;

}

const formatTwoNumbers = (month: any) => {

    let m = month;

    if (m < 10) {

        m = `0${m}`;

    }

    return m;

}

export const fillWithCeros = (inputString: any, length: any) => {
    // Verificar la longitud actual del string
    if (inputString.length < length) {
        // Calcular la cantidad de ceros que se deben agregar
        const cerosLeft = length - inputString.length;

        // Completar con ceros a la izquierda
        const string = '0'.repeat(cerosLeft) + inputString;

        return string;
    } else {
        // Si ya tiene 12 caracteres o más, devolver el string original
        return inputString;
    }
}

export const formatPrice = (price: any) => {

    let p = price;
    const internationarFormat = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 })

    p = internationarFormat.format(p);

    return p;

}

export const sortByDefault = (products: ProductProps[]) => {
    return [...products].sort((a, b) => a.id - b.id);
};

export const sortAZ = (products: ProductProps[]) => {
    return products.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
    });
};

export const sortZA = (products: ProductProps[]) => {
    return products.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
        return 0;
    });
};

export const sortAscending = (products: ProductProps[], profit: number) => {
    return [...products].sort((a, b) => {
        const priceA = profit !== null ? parseInt(`${a.customProfit !== null && a.customProfit !== undefined && a.customProfit >= 0 ? (a.price + a.customProfit) : (a.price * (1 + (profit! / 100)))}`) : 0;
        const priceB = profit !== null ? parseInt(`${b.customProfit !== null && b.customProfit !== undefined && b.customProfit >= 0 ? (b.price + b.customProfit) : (b.price * (1 + (profit! / 100)))}`) : 0;

        return priceA - priceB
    });
};

export const sortDescending = (products: ProductProps[], profit: number) => {
    return [...products].sort((a, b) => {
        const priceA = profit !== null ? parseInt(`${a.customProfit !== null && a.customProfit !== undefined && a.customProfit >= 0 ? (a.price + a.customProfit) : (a.price * (1 + (profit! / 100)))}`) : 0;
        const priceB = profit !== null ? parseInt(`${b.customProfit !== null && b.customProfit !== undefined && b.customProfit >= 0 ? (b.price + b.customProfit) : (b.price * (1 + (profit! / 100)))}`) : 0;

        return priceB - priceA
    });
};

export const getProductsShowed = (productsCurrentPage: number, lengthList: number) => {

    let initial = 1
    let final = 12

    initial = lengthList >= 1 ? ((productsCurrentPage - 1) * 12) + 1 : 0
    final = lengthList <= (productsCurrentPage * 12) ? lengthList : (productsCurrentPage * 12)


    return { initial, final }

}

export const sliceList = (arr: any[], min: number, max: number) => {
    return arr.slice(min, max + 1);
}