import { styled } from 'styled-components';
import { device } from '../styles/stylesConfig';
import { useHistory } from 'react-router-dom';
import { useAnimations } from '../effects/Effects';
import { useLogEvent } from '../hooks/useLogEvent';
import { CSSProperties, memo } from 'react';

const LogoContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: all 0.75s;
    padding: 0;
    cursor: pointer;
`

const LogoImage = styled.img`
    width: 180px;
    height: auto;

    @media ${device.lg} {
        width: 150px;
        height: auto;
    }

    @media ${device.md} {
        width: 120px;
        height: auto;
    }

    @media ${device.sm} {
        width: 100px;
        height: auto;
    }
`

const AppLogo = () => {

    const history: any = useHistory();

    const {
        handleClickGoHome
    } = useAnimations()

    const { handleLogEvent } = useLogEvent();

    return (

        <LogoContainer
            onClick={async () => {

                await history.push('/');

                await handleClickGoHome()

                handleLogEvent('click_logo');

            }}
        >

            <LogoImage
                src={`${process.env.REACT_APP_CDN}/logo.png`}
                alt='logo'
            />

        </LogoContainer>

    )
}

const AppLogoComponent = memo(AppLogo);

export default AppLogoComponent;