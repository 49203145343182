import { styled } from "styled-components";

const ClearComponent = styled.button`
    position: absolute;
    top: 4px;
    bottom: 0;
    right: 10px;
    margin: auto;
    border: none;
    cursor: pointer;
    background-color: transparent;
`
interface Props {
    onClick: () => void;
}

export const ClearButton = ({ onClick }: Props) => {

    return (

        <ClearComponent onClick={() => { onClick() }}>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color={"#000000"} fill={"none"} >
                <path d="M19.0005 4.99988L5.00045 18.9999M5.00045 4.99988L19.0005 18.9999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </ClearComponent>

    )
}