import styled from "styled-components";

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
    position: relative;
    margin: 5vh 0 0vh;
`
const ImageOffer = styled.img`
    height: 90%;
    width: 90%;
    object-fit: contain;
    max-width: 600px;
    max-height: 650px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    background-color: white;
    border-radius: 5px;
`

export const Offer = () => {

    return (

        <ContentContainer className="utils__fade-in-fast-top">

            <ImageOffer
                src={`${process.env.REACT_APP_CDN}/cuotas.webp`}
                alt="Imagen oferta de cuotas Santander"
            />

        </ContentContainer>

    )
}
