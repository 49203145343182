import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from "react-router-dom";
import { HomeScreen } from '../screens/HomeScreen';
import ScrollToTop from "../components/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { StoreProps } from "../interfaces/ReducerInterfaces";
import { useEffect } from "react";
import { getFullDate } from "../utils/Utilities";
import { finishLoading, startLoading } from "../actions/ui";
import { collection, onSnapshot } from "firebase/firestore";
import { removeCheckingFallHerramientasBrands, removeCheckingFallHerramientasProducts, removeCheckingFallHerramientasProfit, saveFallHerramientasBrands, saveFallHerramientasProducts, saveFallHerramientasProfit } from "../actions/fallHerramientas";
import { firestore } from "../firebase/firebase-config";

export const AppRouter = () => {

    // console.warn = function () { };
    // console.error = function () { };

    const dispatch = useDispatch()

    function productsSnapshot(snapshot: any) {

        let allProducts = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });

        dispatch(saveFallHerramientasProducts(allProducts));

        dispatch(removeCheckingFallHerramientasProducts());

    }

    function brandsSnapshot(snapshot: any) {

        let allBrands = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });

        dispatch(saveFallHerramientasBrands(allBrands));

        dispatch(removeCheckingFallHerramientasBrands());

    }


    function profitSnapshot(snapshot: any) {

        let config = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });

        dispatch(saveFallHerramientasProfit(config[0].profit));

        dispatch(removeCheckingFallHerramientasProfit());

    }

    useEffect(() => {

        onSnapshot(collection(firestore, "Products"), productsSnapshot);

        onSnapshot(collection(firestore, "Brands"), brandsSnapshot);

        onSnapshot(collection(firestore, "Config"), profitSnapshot);

        return () => {

            onSnapshot(collection(firestore, "Products"), productsSnapshot);

            onSnapshot(collection(firestore, "Brands"), brandsSnapshot);

            onSnapshot(collection(firestore, "Config"), profitSnapshot);

        }

    }, [
        productsSnapshot,
        brandsSnapshot,
        profitSnapshot
    ]);



    return (

        <Router>

            <div>

                <ScrollToTop />

                <Switch>

                    <Route
                        exact
                        path='/'
                        component={HomeScreen}
                    />

                    <Redirect to='/' />

                </Switch>

            </div>

        </Router>

    )
}
