import { memo, useState } from "react";
import AppLogoComponent from "./AppLogoComponent"
import { styled } from 'styled-components';
import { device } from "../styles/stylesConfig";
import { useAnimations } from "../effects/Effects";
import { useHistory, useLocation } from "react-router-dom";
import { useLogEvent } from "../hooks/useLogEvent";
import { useSelector } from "react-redux";
import { StoreProps } from "../interfaces/ReducerInterfaces";
import ScrollToTop from "./ScrollToTop";

const Container = styled.div`
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 1000;
    box-shadow: 0px 4px 12px 0px rgba(255, 255, 255, 0.3);
    transition: all 0.75s ease-in;
    background-color: #000;

    @media ${device.lg} {
        height: fit-content;
        padding: 15px 0px;
    }

    @media ${device.sm} {
        padding: 10px 0px;
    }

`

const MenuDiv = styled.div`
    width: 90%;
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media ${device.lg} {
        flex-direction: column;
    }

`

const NavDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 70px;
    color: white;
    align-items: center;
    justify-content: space-between;

    @media ${device.lg} {
        margin-top: 15px;
    }

    @media ${device.md} {
        margin-top: 10px;
    }

    @media ${device.sm} {
        margin-top: 10px;
        column-gap: 30px;
    }

    @media ${device.xs} {
        column-gap: 10px;
    }


`

const NavButton = styled.button`
    color: white;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 10px 5px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;

    @media ${device.md} {
        font-size: 12px;
    }

    @media ${device.sm} {
        font-size: 10px;
    }

    &::before {
        content: '';
        height: 5px;
        width: 0%;
        bottom: 0;
        left: 0;
        position: absolute;
        background-color: #FF6B05;
        transition: width 0.3s ease;
    }

    &:focus,
    &:hover {
        outline: none;

        &::before {
            width: 100%;
        }

        & > div {
            visibility: visible !important;
        }
        
    }

`

export const HeaderComponent = () => {

    const {
        handleClickGoHome,
        handleClickGoProducts,
        handleClickGoContact,
    } = useAnimations()

    ScrollToTop()

    const { handleLogEvent } = useLogEvent();

    return (

        <Container>

            <MenuDiv>

                <AppLogoComponent />

                <NavDiv>

                    <NavButton onClick={async (e: any) => {

                        handleClickGoHome();

                        handleLogEvent('click_boton_inicio_menu');

                        e.target?.blur()

                    }}>
                        Inicio
                    </NavButton>

                    <NavButton onClick={async (e: any) => {

                        handleClickGoProducts();

                        handleLogEvent('click_boton_nosotros_menu');

                        e.target?.blur()

                    }}>

                        Catalogo

                    </NavButton>

                    <NavButton onClick={async (e: any) => {

                        handleClickGoContact()

                        handleLogEvent('click_boton_contacto_menu');

                        e.target?.blur()

                    }}>
                        Contacto
                    </NavButton>

                </NavDiv>

            </MenuDiv>

        </Container>

    )
}

const Header = memo(HeaderComponent);

export default Header;
