import { FallHerramientasReducerProps } from '../interfaces/ReducerInterfaces';
import { types } from '../types/types';

const initialState: FallHerramientasReducerProps = {
    brands: [],
    checkingBrands: false,
    products: [],
    checkingProducts: false,
    sold: [],
    checkingSold: false,
    profit: null,
    checkingProfit: false,
    members: [
        {
            id: 1,
            name: "Persona sin acceso",
            email: 'tomasquesada11@gmail.com',
            role: 'None'
        },
        {
            id: 2,
            name: "Persona miembro admin",
            email: 'fallisqtoffherramientas@gmail.com',
            role: 'Administrador'
        },
        {
            id: 3,
            name: "Persona miembro Proveedor miembro Proveedor",
            email: 'srandamios.presupuestos@gmail.com',
            role: 'Proveedor'
        },
        {
            id: 4,
            name: "Persona sin acceso",
            email: 'algo@gmail.com',
            role: 'None'
        },
        {
            id: 5,
            name: "Persona miembro admin",
            email: 'algo@gmail.com',
            role: 'Administrador'
        },
        {
            id: 6,
            name: "Persona miembro Proveedor",
            email: 'algo@gmail.com',
            role: 'Proveedor'
        },
        {
            id: 7,
            name: "Persona sin acceso",
            email: 'algo@gmail.com',
            role: 'None'
        },
    ],
    checkingMembers: false,
}

export const falHerramientasReducer = (state = initialState, action: any) => {

    switch (action.type) {

        case types.fallHerramientasSetProducts:
            return {
                ...state,
                products: action.payload.products
            }

        case types.fallHerramientasRemoveProducts:
            return {
                ...state,
                products: []
            }

        case types.setCheckingFallHerramientasProducts:
            return {
                ...state,
                checkingProducts: true
            }

        case types.removeCheckingFallHerramientasProducts:
            return {
                ...state,
                checkingProducts: false
            }

        case types.fallHerramientasSetBrands:
            return {
                ...state,
                brands: action.payload.brands
            }

        case types.fallHerramientasRemoveBrands:
            return {
                ...state,
                brands: []
            }

        case types.setCheckingFallHerramientasBrands:
            return {
                ...state,
                checkingBrands: true
            }

        case types.removeCheckingFallHerramientasBrands:
            return {
                ...state,
                checkingBrands: false
            }

        case types.fallHerramientasSetSold:
            return {
                ...state,
                sold: action.payload.sold
            }

        case types.fallHerramientasRemoveSold:
            return {
                ...state,
                sold: null
            }

        case types.setCheckingFallHerramientasSold:
            return {
                ...state,
                checkingSold: true
            }

        case types.removeCheckingFallHerramientasSold:
            return {
                ...state,
                checkingSold: false
            }

        case types.fallHerramientasSetProfit:
            return {
                ...state,
                profit: action.payload.profit
            }

        case types.fallHerramientasRemoveProfit:
            return {
                ...state,
                profit: []
            }

        case types.setCheckingFallHerramientasProfit:
            return {
                ...state,
                checkingProfit: true
            }

        case types.removeCheckingFallHerramientasProfit:
            return {
                ...state,
                checkingProfit: false
            }

        case types.fallHerramientasSetMembers:
            return {
                ...state,
                members: action.payload.members
            }

        case types.fallHerramientasRemoveMembers:
            return {
                ...state,
                members: []
            }

        case types.setCheckingFallHerramientasMembers:
            return {
                ...state,
                checkingMembers: true
            }

        case types.removeCheckingFallHerramientasMembers:
            return {
                ...state,
                checkingMembers: false
            }

        default:
            return state;

    }

}