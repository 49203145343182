import styled, { CSSProperties } from "styled-components"
import { device } from "../styles/stylesConfig"
import { memo } from "react";

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-top: 50px;
    margin-bottom: 10px;

    h1 {
        width: 90%;
        font-size: 42px;
        text-transform: uppercase;
        color: #000;
        text-align: center;

        @media ${device.lg} {
            font-size: 34px;
        }

        @media ${device.md} {
            font-size: 28px;
        }

        @media ${device.sm} {
            font-size: 24px;
        }

        @media ${device.xs} {
            font-size: 18px;
        }

    }

    p {
        width: 90%;
        color: #2E2D2D;
        margin-top: 30px;
        text-align: center;
        line-height: 30px;
        font-size: 14px;

        @media ${device.lg} {
            /* width: 65%; */
            width: 85%;
        }

        @media ${device.md} {
            width: 85%;
        }

        @media ${device.sm} {
            font-size: 12px;
            line-height: 24px;
            width: 90%;
        }

    }
    
    @media ${device.sm} {
        margin-bottom: 0px;
    }

`

interface Props {
    title: string;
    subtitle?: string;
    textAlign?: 'start' | 'center' | 'end',
    titleColor?: string;
    titleLighter?: boolean;
    titleStyle?: CSSProperties
    style?: CSSProperties
    id?: string
}

const TitleComponent = ({ title, subtitle, textAlign, titleColor, titleLighter, titleStyle, style, id }: Props) => {

    return (

        <TitleContainer id={id} style={{ ...style }} className="utils__fade-in-fast-top">

            <h1 style={{ textAlign, color: titleColor, fontWeight: titleLighter ? 'normal' : 'bold', ...titleStyle }} className="utils__fade-in-fast-top">{title}</h1>

            {subtitle && <p style={{ textAlign }} className="utils__fade-in-fast-top">{subtitle}</p>}

        </TitleContainer>

    )
}

const Title = memo(TitleComponent);

export default Title;