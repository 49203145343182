import styled from "styled-components"
import { useLogEvent } from "../hooks/useLogEvent"
import { device } from "../styles/stylesConfig"
import Social from "./Social"
import AppLogoComponent from "./AppLogoComponent"

const Container = styled.div`
    width: 100%;
    background-color: #000000;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    position: relative;
    padding: 5vh 0;

`

const LogoContainer = styled.div`
    width: 90%;
    max-width: 1400px;
    background-color: #000000;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    position: relative;
    margin-bottom: 50px;

    @media ${device.md} {
        justify-content: center;
        margin-bottom: 50px;
    }

    @media ${device.sm} {
        margin-bottom: 30px;
    }

`

const FooterContentContainer = styled.div`
    max-width: 1400px;
    width: 90%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 50px;
    align-items: center;
    position: relative;

    @media ${device.md} {
        width: 80%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }
`

const FooterButtonsContainer = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 10px 0px;

    @media ${device.md} {
        width: 100%;
        align-items: center;
        padding: 0px;
    }
`

const FooterButton = styled.a`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @media ${device.md} {
        margin-bottom: 0px;
        align-items: center;
    }

    & > img {
        height: 24px;
        width: 24px;
    }

    & > h3 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.4px;
        text-decoration-line: underline;

        @media ${device.md} {
            font-size: 15px;
        }
        
        @media ${device.sm} {
            font-size: 12px;
            text-align: center;
        }

        @media ${device.xs} {
            font-size: 10px;
            text-align: center;
        }
    }

`

const FooterMaps = styled.iframe`
    width: 100%;
    height: 100%;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.75);
    justify-self: end;

    @media ${device.md} {
        width: 100%;
        height: 300px;
        align-items: center;
    }

`

const FooterLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    &:last-of-type {
        margin-top: 50px;

        @media ${device.sm} {
            margin-top: 30px;
        }


    }

    & > img {
        width: 25px;
        height: 25px;

        @media ${device.md} {
            width: 25px;
            height: 25px;
        }
    }

    & > h3 {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 10px;
        padding-left: 20px;

        @media ${device.lg} {
            font-size: 18px;
        }

        @media ${device.md} {
            font-size: 16px;
        }

        @media ${device.sm} {
            font-size: 14px;
        }

        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.1px;
        text-transform: uppercase;
    
    }

`

export const Footer = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <Container>

            <LogoContainer id="contact" className='utils__fade-in-fast-top'>

                <AppLogoComponent />

            </LogoContainer>

            <FooterContentContainer>

                <FooterButtonsContainer>

                    <FooterLabelContainer className='utils__fade-in-fast-top'>

                        <img
                            src={require('../images/grupo.png')}
                            alt='Logo 0'
                        />

                        <h3>NUESTRAS REDES</h3>

                    </FooterLabelContainer>

                    <Social />

                    <FooterLabelContainer className='utils__fade-in-fast-top'>

                        <img
                            src={require('../images/ubicacion.webp')}
                            alt='Logo 1'
                        />

                        <h3>UBICACIÓN</h3>

                    </FooterLabelContainer>

                    <FooterButton className='utils__fade-in-top'
                        href="https://maps.app.goo.gl/x27RPkfQyt75B4X4A"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_ubicacion');
                        }}
                    >

                        <h3>Av. Dr. Ricardo Balbín 830, San Miguel</h3>

                    </FooterButton>

                    <FooterLabelContainer className='utils__fade-in-fast-top'>

                        <img
                            src={require('../images/telefono.webp')}
                            alt='Logo 2'
                        />

                        <h3>CONTACTO</h3>

                    </FooterLabelContainer>

                    <FooterButton
                        className='utils__fade-in-top'
                        href="tel:+5491133133735"
                        onClick={() => {
                            handleLogEvent('Click_boton_telefono');
                        }}
                    >

                        <h3>+54 011 3313-3735</h3>

                    </FooterButton>

                </FooterButtonsContainer>

                <FooterMaps
                    className="footer__maps utils__fade-in-fast-center"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d955.3960380052349!2d-58.7092048779554!3d-34.53937931553976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcbdd3e77b8885%3A0x788ad54b87cb61c8!2sFAL%20HERRAMIENTAS%20Y%20BOMBAS%20DE%20AGUA!5e0!3m2!1ses!2sar!4v1719931627494!5m2!1ses!2sar"
                    loading="lazy" />

            </FooterContentContainer>


        </Container>

    )
}
