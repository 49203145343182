import { AccessProps } from '../interfaces/AuthInterfaces';
import { ProductProps } from '../interfaces/ProductInterfaces';
import { SoldProps } from '../interfaces/SoldInterfaces';
import { types } from '../types/types';

// PRODUCTS

export const saveFallHerramientasProducts = (productsList: ProductProps[]) => (
    {
        type: types.fallHerramientasSetProducts,
        payload: {
            products: productsList
        }
    }
)

export const removeFallHerramientasProducts = () => ({
    type: types.fallHerramientasRemoveProducts,
})

export const setCheckingFallHerramientasProducts = () => ({
    type: types.setCheckingFallHerramientasProducts,
})

export const removeCheckingFallHerramientasProducts = () => ({
    type: types.removeCheckingFallHerramientasProducts,
})

// BRANDS

export const saveFallHerramientasBrands = (brandsList: any) => ({
    type: types.fallHerramientasSetBrands,
    payload: {
        brands: brandsList
    }
})

export const removeFallHerramientasBrands = () => ({
    type: types.fallHerramientasRemoveBrands,
})

export const setCheckingFallHerramientasBrands = () => ({
    type: types.setCheckingFallHerramientasBrands,
})

export const removeCheckingFallHerramientasBrands = () => ({
    type: types.removeCheckingFallHerramientasBrands,
})

// SOLD

export const saveFallHerramientasSold = (soldList: SoldProps[]) => ({
    type: types.fallHerramientasSetSold,
    payload: {
        sold: soldList
    }
})

export const removeFallHerramientasSold = () => ({
    type: types.fallHerramientasRemoveSold,
})

export const setCheckingFallHerramientasSold = () => ({
    type: types.setCheckingFallHerramientasSold,
})

export const removeCheckingFallHerramientasSold = () => ({
    type: types.removeCheckingFallHerramientasSold,
})

// PROFIT

export const saveFallHerramientasProfit = (profit: number) => ({
    type: types.fallHerramientasSetProfit,
    payload: {
        profit
    }
})

export const removeFallHerramientasProfit = () => ({
    type: types.fallHerramientasRemoveProfit,
})

export const setCheckingFallHerramientasProfit = () => ({
    type: types.setCheckingFallHerramientasProfit,
})

export const removeCheckingFallHerramientasProfit = () => ({
    type: types.removeCheckingFallHerramientasProfit,
})

// ACCESS

export const saveFallHerramientasMembers = (accessList: AccessProps[]) => ({
    type: types.fallHerramientasSetMembers,
    payload: {
        members: accessList
    }
})

export const removeFallHerramientasMembers = () => ({
    type: types.fallHerramientasRemoveMembers,
})

export const setCheckingFallHerramientasMembers = () => ({
    type: types.setCheckingFallHerramientasMembers,
})

export const removeCheckingFallHerramientasMembers = () => ({
    type: types.removeCheckingFallHerramientasMembers,
})