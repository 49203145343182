import { CSSProperties } from "react"

interface Props {
    size: 'S' | 'M' | 'L' | 'XL' | 'XXL'
    src: string
    style?: CSSProperties
    className?: string
}

export const Icon = ({ size, src, style, className }: Props) => {

    const setSize = () => {
        switch (size) {
            case 'S':
                return 10
            case 'M':
                return 20
            case 'L':
                return 30
            case 'XL':
                return 40
            case 'XXL':
                return 50
        }
    }

    return (

        <img
            className={className}
            style={{
                width: setSize(),
                height: 'auto',
                ...style
            }}
            src={src}
            alt='logo'
        />

    )
}
