import { initializeApp } from "firebase/app";
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from "firebase/performance";
import { getFirestore } from "firebase/firestore";

const firebase = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID

});

const analytics = getAnalytics(firebase);

const performance = getPerformance(firebase);

const firestore = getFirestore(firebase);

analytics.app.automaticDataCollectionEnabled = true;

performance.app.automaticDataCollectionEnabled = true;

export {
  firebase,
  analytics,
  firestore
}