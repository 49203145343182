import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Button } from "../molecules/Button";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAnimations } from "../../effects/Effects";
import { sliceList } from "../../utils/Utilities";
import { Typography } from "../atoms/Typography";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    z-index: 1;

`
const OptionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > button {
        height: 30px;
        margin-right: 20px;
        font-size: 14px !important;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

    }

    @media ${device.lg} {
    
        & > button {
            margin-right: 10px;
        }
    }

    @media ${device.md} {
    
        & > button {
            margin-right: 10px;
            font-size: 12px !important;
        }
    }
    
`

interface Props {
    productsCurrentPage: number
    lengthList: number
    setProductsCurrentPage: React.Dispatch<React.SetStateAction<number>>
    onClickChangePage: () => void
}

export const ProductPagination = ({ lengthList, productsCurrentPage, setProductsCurrentPage, onClickChangePage }: Props) => {

    const history = useHistory();

    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateWindowWidth = () => {

        setWindowWidth(window.innerWidth);

    }

    const handleChangeCurrentPage = (newPosition: number) => {

        setProductsCurrentPage(newPosition);

        if (newPosition > 1) {

            params.set('p', newPosition.toString());

            history.push(`/?${params.toString()}`);

        } else {

            params.delete('p');

            history.push({ search: params.toString() });

        }

        if (productsCurrentPage !== newPosition) {

            onClickChangePage()

            setTimeout(() => {

                handleClickGoProducts()

            }, 300);
        }


    }

    const {
        handleClickGoProducts
    } = useAnimations()

    const getPagesToShow = () => {

        let array = [...Array(Math.ceil(lengthList / 12))].map((value, index, array) => { return index + 1 })

        let pagesToShow = 1;

        const list = sliceList(array, (productsCurrentPage - pagesToShow - 1) < 0 ? 0 : productsCurrentPage - pagesToShow - 1, productsCurrentPage + pagesToShow - 1 >= lengthList ? lengthList : productsCurrentPage + pagesToShow - 1)

        return list

    }

    useEffect(() => {

        window.addEventListener('resize', updateWindowWidth)

        return () => {
            window.removeEventListener('resize', updateWindowWidth)
        }

    }, [])


    return (

        <MainContainer className="utils__fade-in-fast-top">

            <OptionButtonsContainer>

                <Button
                    type={'light'}
                    text={'<'}
                    style={{ display: productsCurrentPage > 1 ? 'unset' : 'none', marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark, width: 0, padding: '10px 20px', transition: 'none' }}
                    onClick={() => {

                        if (productsCurrentPage > 1) {

                            handleChangeCurrentPage(productsCurrentPage - 1)

                        }

                    }}
                />

                {
                    windowWidth >= 600 &&
                    !getPagesToShow().includes(1) && (

                        <>

                            <Button
                                key={'1'}
                                type={'light'}
                                text={'1'}
                                style={{ marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark, width: 0, padding: '10px 20px' }}
                                onClick={() => {

                                    handleChangeCurrentPage(1);

                                }}
                            />

                            <Button
                                key={'...1'}
                                type={'light'}
                                text={'. . .'}
                                disabled
                                style={{ marginTop: 0, fontSize: 40, fontWeight: 500, color: colors.primary_dark, width: 0, padding: '0px 5px', border: 'unset', textWrap: 'nowrap', alignSelf: 'end', boxShadow: 'unset', backgroundColor: 'unset' }}
                                onClick={() => { }}
                            />

                        </>

                    )
                }

                {

                    getPagesToShow().map((value, index) => {


                        return (

                            <Button
                                key={(value).toString()}
                                type={productsCurrentPage === value ? 'full light' : 'light'}
                                text={(value).toString()}
                                disabled={Math.ceil(lengthList / 12) <= 1}
                                style={{ marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark, width: 0, padding: '10px 20px' }}
                                onClick={() => {

                                    handleChangeCurrentPage(value)

                                }}
                            />

                        )
                    })
                }

                {
                    windowWidth >= 600 &&
                    !getPagesToShow().includes(Math.ceil(lengthList / 12)) && (

                        <>

                            <Button
                                key={'...2'}
                                type={'light'}
                                text={'. . .'}
                                disabled
                                style={{ marginTop: 0, fontSize: 40, fontWeight: 500, color: colors.primary_dark, width: 0, padding: '0px 5px', border: 'unset', textWrap: 'nowrap', alignSelf: 'end', boxShadow: 'unset', backgroundColor: 'unset' }}
                                onClick={() => { }}
                            />

                            <Button
                                key={Math.ceil(lengthList / 12)}
                                type={'light'}
                                text={Math.ceil(lengthList / 12).toString()}
                                style={{ marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark, width: 0, padding: '10px 20px' }}
                                onClick={() => {

                                    handleChangeCurrentPage(Math.ceil(lengthList / 12));

                                }}
                            />



                        </>

                    )
                }

                <Button
                    type={'light'}
                    text={'>'}
                    style={{ visibility: productsCurrentPage < Math.ceil(lengthList / 12) ? 'visible' : 'hidden', marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark, width: 0, padding: '10px 20px', transition: 'none' }}
                    onClick={() => {

                        if (productsCurrentPage < Math.ceil(lengthList / 12)) {

                            handleChangeCurrentPage(productsCurrentPage + 1)

                        }

                    }}
                />



            </OptionButtonsContainer>

        </MainContainer>

    )
}