import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { finishLoading } from "../actions/ui"
import { device } from "../styles/stylesConfig";
import LoadingComponent from "./LoadingComponent";

const Container = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

     &::after {
        content: '';
        position: absolute;
        margin: auto;
        width: 150%;
        height: 150px;
        left: -50%;
        bottom: -200px;
        background-color: #FF6B05;
        box-shadow: 50px -75px 20px 0px #FF6B05;

        @media ${device.md} {
            width: 150%;
            height: 150px;
            left: -50%;
            bottom: -200px;
            background-color: #FF6B05;
            box-shadow: 50px -75px 20px 0px #FF6B05;
        }

        @media ${device.sm} {
            width: 150%;
            height: 150px;
            left: -50%;
            bottom: -215px;
            background-color: #FF6B05;
            box-shadow: 50px -75px 10px 0px #FF6B05;
        }

    } 
    
`

const VideoContainer = styled.video`
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    object-fit: contain !important;
    margin-top: 100px;

    @media ${device.lg} {
        margin-top: 145px;
    }

    @media ${device.md} {
        margin-top: 125px;
        width: 105vw;
    }

    @media ${device.sm} {
        margin-top: 105px;
        width: 110vw;
    }

`

const Loading = styled.div`
    position: absolute;
    bottom: 15%;
    
`

export const Introduction = () => {

    const { loading } = useSelector((state: any) => state.ui);

    const dispatch = useDispatch()

    const [introImages] = useState(`${process.env.REACT_APP_CDN}/intro0.mp4`);

    const videoRef = useRef<HTMLVideoElement>(null);

    const [isPlaying, setIsPlaying] = useState(false);

    const [isMuted, setIsMuted] = useState(true);

    const handleVolumeChange = () => {
        if (videoRef.current) {
            videoRef.current.volume = 0.5;
        }
    };

    useEffect(() => {

        handleVolumeChange()

        return () => handleVolumeChange();
    }, [])

    useEffect(() => {

        setTimeout(() => {

            window.scrollTo(0, 0);

        }, 2000);

    }, [loading])

    return (

        <Container id="home">

            {
                introImages ? (

                    <VideoContainer
                        ref={videoRef}
                        autoPlay
                        muted
                        loop
                        playsInline
                        onPlay={() => {
                            setIsPlaying(true);

                            // setTimeout(() => {
                            //     setIsMuted(false)
                            // }, 5000);

                        }}
                        onLoadedData={() => { dispatch(finishLoading()) }}
                        onSeeked={() => { setIsMuted(true) }}
                    >
                        <source src={introImages} type="video/mp4" />
                    </VideoContainer>

                ) : <></>
            }

            {
                !isPlaying && (

                    <Loading>
                        <LoadingComponent />
                    </Loading>
                )
            }

        </Container >


    )
}

export default Introduction;