
export const types = {

    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    fallHerramientasSetProducts: '[FALL Herramientas] Set Products',
    fallHerramientasRemoveProducts: '[FALL Herramientas] Remove Products',

    setCheckingFallHerramientasProducts: '[FALL Herramientas] Set Checking Products',
    removeCheckingFallHerramientasProducts: '[FALL Herramientas] Remove Checking Products',

    fallHerramientasSetBrands: '[FALL Herramientas] Set Brands',
    fallHerramientasRemoveBrands: '[FALL Herramientas] Remove Brands',

    setCheckingFallHerramientasBrands: '[FALL Herramientas] Set Checking Brands',
    removeCheckingFallHerramientasBrands: '[FALL Herramientas] Remove Checking Brands',

    fallHerramientasSetSold: '[FALL Herramientas] Set Sold',
    fallHerramientasRemoveSold: '[FALL Herramientas] Remove Sold',

    setCheckingFallHerramientasSold: '[FALL Herramientas] Set Checking Sold',
    removeCheckingFallHerramientasSold: '[FALL Herramientas] Remove Checking Sold',

    fallHerramientasSetProfit: '[FALL Herramientas] Set Profit',
    fallHerramientasRemoveProfit: '[FALL Herramientas] Remove Profit',

    setCheckingFallHerramientasProfit: '[FALL Herramientas] Set Checking Profit',
    removeCheckingFallHerramientasProfit: '[FALL Herramientas] Remove Checking Profit',

    fallHerramientasSetMembers: '[FALL Herramientas] Set Members',
    fallHerramientasRemoveMembers: '[FALL Herramientas] Remove Members',

    setCheckingFallHerramientasMembers: '[FALL Herramientas] Set Checking Members',
    removeCheckingFallHerramientasMembers: '[FALL Herramientas] Remove Checking Members',
}