import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { Icon } from "./atoms/Icon"
import LoadingScreen from "./LoadingScreen"
import LoadingComponent from "./LoadingComponent"


const Container = styled.div`
    width: 95vw;
    max-width: 1800px;
    padding: 10vh 0;
    min-height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 1fr;
    gap: 80px;
    justify-content: center;
    align-items: center;
    position: relative;
    align-self: start;

    @media ${device.md} {
        grid-template-columns: repeat(1, auto);
        grid-template-rows: repeat(2, auto);
        width: 90vw;
        gap: 20px;
        align-self: center !important;
    }

`

const InfoContainer = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 15px;
    padding: 20px 30px;
    background-color: white;
    position: relative;
    height: 100%;

    p:last-of-type {
        margin-bottom: 0 !important;
    }

    
    @media ${device.md} {
        border-radius: 15px !important;
        width: 80% !important;
        justify-self: center;
        height: fit-content;
        padding: 30px 30px;

        h2 {
            /* text-align: start !important; */
            width: fit-content;
            align-self: start;
        }

        br {
            /* display: none; */
        }

        & > img:first-of-type {
            display: none;
        }

        & > img:last-of-type {
            width: 350px !important;
            bottom: -40px !important;
            right: -40px !important;
        }

    }
    
    @media ${device.sm} {

        & > img:last-of-type {
            width: 210px !important;
            bottom: 0px !important;
            right: -25px !important;
        }

    }

    @media ${device.xs} {

        & > img:last-of-type {
            width: 150px !important;
            bottom: -10px !important;
            right: -20px !important;
        }

    }

`

const Title = styled.h2`
    width: 100%;
    font-weight: 700;
    font-size: 28px;
    color: #111;
    margin-bottom: 20px;
    text-transform: uppercase;
    
    @media ${device.xl} {
        font-size: 22px;
    }

    @media ${device.lg} {
        font-size: 20px;
    }

    @media ${device.md} {
        font-size: 18px;
    }

    @media ${device.sm} {
        font-size: 16px;
    }

    @media ${device.xs} {
        font-size: 14px;
    }
`

const Paragraph = styled.p`
    width: 100%;
    font-size: 18px;
    color: #111;
    line-height: 25px;

    @media ${device.lg} {
        font-size: 16px;
    }

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }
`

export const About = () => {

    return (

        <Container>

            <InfoContainer
                className="utils__fade-in-fast-top"
                style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >


                <Title className="utils__fade-in-top">Sobre Nosotros</Title>

                <Paragraph className="utils__fade-in-top">Somos una empresa con más de 20 años de experiencia en el rubro. Tenemos las herramientas y productos necesarios para el mantenimiento de tu hogar, negocio, oficina o empresa.</Paragraph>

                <Paragraph className="utils__fade-in-top"><span style={{ fontWeight: 600 }}>Con el objetivo de brindar a nuestros clientes calidad, precio y garantía.</span></Paragraph>

            </InfoContainer>

            <InfoContainer style={{ width: 'fit-content' }} className="utils__fade-in-fast-top">

                <Title className="utils__fade-in-top" style={{ textAlign: 'center', margin: 0 }}>ENVIOS <br />A TODO EL PAíS</Title>

                <Icon
                    size="XXL"
                    src={`${process.env.REACT_APP_CDN}/delivery.webp`}
                    style={{
                        width: 200,
                        marginTop: 10,
                        visibility: 'hidden'
                    }}
                />

                <Icon
                    size="XXL"
                    className="utils__fade-in-left"
                    src={`${process.env.REACT_APP_CDN}/delivery.webp`}
                    style={{
                        width: 300,
                        position: 'absolute',
                        bottom: -10,
                        right: -50,
                    }}
                />
            </InfoContainer>

        </Container>

    )
}

export default About;