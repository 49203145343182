import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Typography } from "../atoms/Typography";
import { ProductProps } from "../../interfaces/ProductInterfaces";
import { Icon } from "../atoms/Icon";
import { formatPrice } from "../../utils/Utilities";
import { Button } from "./Button";
import { useSelector } from "react-redux";
import { StoreProps } from "../../interfaces/ReducerInterfaces";

const ButtonCard = styled.button`
    background-color: white;
    border-radius: 5px;
    width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
    border: ${colors.primary_light} 1px solid;
    box-shadow: 0px 0px 12px #00000050;
    transition: all 0.1s ease;
    align-self: center;
    justify-self: center;

    @media ${device.xl} {
        width: 260px;
        height: 400px;
    }

    @media ${device.sm} {
        width: 170px;
        height: 320px;
        padding: 15px;

    }

    @media ${device.xs} {
        width: 130px;
        height: 260px;
        padding: 10px;
    }

    hr {
        border: none;

        @media ${device.xs} {
            position: absolute;
            visibility: hidden;
        }
    }

    img {
        width: 180px !important; 
        height: 180px !important; 
        object-fit: contain !important; 
        object-position: center !important;

        @media ${device.xl} {
            width: 150px !important; 
            height: 150px !important; 
        }

        @media ${device.sm} {
            width: 100px !important; 
            height: 100px !important; 
        }

        @media ${device.xs} {
            width: 80px !important; 
            height: 80px !important; 
        }
    }
`


const Info = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.sm} {

        & > h1:first-of-type {
            font-size: 12px;
        }
        & > h1:last-of-type {
            margin-top: 10px !important;
            font-size: 18px;
            align-self: center !important;
            margin-left: unset !important;
        }
    }

    @media ${device.xs} {

        & > h1:first-of-type {
            font-size: 10px;
        }
        & > h1:last-of-type {
            font-size: 14px;
        }
    }

    & > button {
        padding: 10px 30px; 
        margin-top: 15px; 
        background-color: #24D466;
        
        @media ${device.xl} {
            padding: 10px 20px; 
        }

        @media ${device.sm} {
            margin-top: 10px;
            padding: 7px 20px; 
            font-size: 12px;
        }

        @media ${device.xs} {
            padding: 5px 10px; 
            font-size: 10px;

        }

    }


`

interface Props {
    data: ProductProps;
    onClick: () => void
}

export const ProductCardItem = ({ data, onClick }: Props) => {

    const { profit } = useSelector((state: StoreProps) => state.fallherramientas);


    return (

        <ButtonCard >

            <hr />

            <Icon
                size="XXL"
                src={data.image ?? require('../../images/boy.jpg')}
            />

            <Info>

                <Typography
                    size='14px'
                    color="#111"
                    style={{
                        textTransform: 'uppercase',
                    }}
                >
                    {`${data.name} [${data.model}]`}
                </Typography>

                {
                    (profit !== null && data.stockAmount !== 0) && (

                        <Typography
                            size='26px'
                            color="#111"
                            bold
                            style={{
                                marginTop: 15,
                                width: 'fit-content',
                                alignSelf: 'start',
                                marginLeft: 15,
                                fontWeight: 700
                            }}
                        >
                            {`$ ${data.customProfit !== null && data.customProfit !== undefined && data.customProfit >= 0 ? formatPrice(data.price + data.customProfit) : formatPrice(data.price * (1 + (profit! / 100)))}`}
                        </Typography>

                    )
                }

                <Button
                    type="primary"
                    text={data.stockAmount === 0 ? "Sin stock" : "Solicitar por WhatsApp"}
                    onClick={() => { onClick() }}
                    disabled={data.stockAmount === 0}
                />

            </Info>

        </ButtonCard>

    )
}
