import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { Button } from "../molecules/Button";
import { Input } from "../molecules/Input";
import { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { ClearButton } from "../atoms/ClearButton";
import { Typography } from "../atoms/Typography";
import { OrderTypes } from "../../interfaces/ProductInterfaces";
import { useHistory, useLocation } from "react-router-dom";
import { formatPrice, getProductsShowed } from "../../utils/Utilities";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;
    z-index: 1;
    padding-top: 10px;
    
    @media ${device.lg} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media ${device.md} {
        align-items: start;
        padding-top: 0px;
    }
`

const AmountAndSortContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    padding: 0px 20px 10px;
    margin-top: 50px;
    
    @media ${device.lg} {
        align-self: center;
        width: 90%;
        max-width: 798px;
        padding: 0px;
    }

    @media ${device.sm} {
        flex-direction: column-reverse;
        align-items: start;

        & > h1 {
            margin-top: 25px;
        }
    }
`

const OptionButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 20px 20px 10px 0px;

    & > button {
        height: 30px;
        margin-right: 20px;
        font-size: 14px !important;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

    }

    @media ${device.lg} {
        width: fit-content;
    
        & > button {
            margin-right: 10px;
        }
    }

    @media ${device.lg} {
        width: 100%;
    
        & > button {
            font-size: 12px !important;
        }
    }
    
`

interface Props {
    brandSelected: number | null
    searchProduct: string
    productsOrder: OrderTypes
    productsCurrentPage: number
    setSearchProduct: React.Dispatch<React.SetStateAction<string>>
    setBrandSelected: React.Dispatch<React.SetStateAction<number | null>>
    setProductsOrder: React.Dispatch<React.SetStateAction<OrderTypes>>
    setProductsCurrentPage: React.Dispatch<React.SetStateAction<number>>
    lengthList: number
    style?: CSSProperties
}

export const ProductBrandsHeader = ({ searchProduct, brandSelected, lengthList, productsCurrentPage, productsOrder, setProductsCurrentPage, setSearchProduct, setBrandSelected, setProductsOrder, style }: Props) => {

    const history = useHistory();

    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const { brands } = useSelector((state: StoreProps) => state.fallherramientas);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateWindowWidth = () => {

        setWindowWidth(window.innerWidth);

    }

    useEffect(() => {

        window.addEventListener('resize', updateWindowWidth)

        return () => {
            window.removeEventListener('resize', updateWindowWidth)
        }

    }, [])

    return (

        <MainContainer style={{ ...style }}>

            <OptionButtonsContainer className="utils__fade-in-fast-top">

                <Button
                    key={0}
                    type={brandSelected === null ? 'full light' : 'light'}
                    text={'TODAS'}
                    style={{ marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark }}
                    onClick={() => {

                        setBrandSelected(null);

                        params.delete('b');

                        history.push({ search: params.toString() });

                        if (brandSelected !== null) {

                            params.delete('p');

                            setProductsCurrentPage(1);

                        }
                    }}
                />

                {

                    brands.map((value) => {

                        const { id, name } = value

                        return (

                            <Button
                                key={id}
                                type={brandSelected === id ? 'full light' : 'light'}
                                text={name.toUpperCase()}
                                style={{ marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark }}
                                onClick={() => {

                                    setBrandSelected(id)

                                    if (brandSelected !== id) {

                                        params.delete('p');

                                        setProductsCurrentPage(1);

                                    }

                                    if (id !== null) {

                                        params.set('b', id.toString());

                                        history.push(`/?${params.toString()}`);

                                    } else {

                                        params.delete('b');

                                        history.push({ search: params.toString() });

                                    }
                                }}
                            />

                        )
                    })
                }

            </OptionButtonsContainer>

            <AmountAndSortContainer className="utils__fade-in-fast-top">

                <Typography size="14px" color={colors.light}>Mostrando {`${getProductsShowed(productsCurrentPage, lengthList).initial}-${getProductsShowed(productsCurrentPage, lengthList).final}`} de {formatPrice(lengthList)} resultados</Typography>

                <Input
                    label="Ordenar"
                    type="select"
                    value={productsOrder}
                    labelTheme="light"
                    theme="light"
                    // incomplete={fieldIncomplete.brandId}
                    inputStyle={{ borderColor: 'white' }}
                    style={{
                        width: windowWidth > 400 ? 240 : '100%',
                        margin: 0
                    }}
                    onChange={(e) => {

                        const newOrder = e.target.value as OrderTypes

                        setProductsOrder(newOrder);

                        if (productsOrder !== newOrder) {

                            params.delete('p');

                            setProductsCurrentPage(1);

                        }

                        if (newOrder !== '-') {

                            params.set('o', newOrder);

                            history.push(`/?${params.toString()}`);

                        } else {

                            params.delete('o');

                            history.push({ search: params.toString() });

                        }

                    }}
                >
                    <option value={'-' as OrderTypes}>Por defecto</option>
                    <option value={'0-9' as OrderTypes}>De menor a mayor precio</option>
                    <option value={'9-0' as OrderTypes}>De mayor a menor precio</option>
                    <option value={'A-Z' as OrderTypes}>De la A a la Z </option>
                    <option value={'Z-A' as OrderTypes}>De la Z a la A </option>

                </Input>

            </AmountAndSortContainer>

            <Input
                className="utils__fade-in-fast-top"
                style={{
                    marginBottom: windowWidth <= 1280 ? 30 : 16,
                    width: windowWidth > 1280 ? 400 : '90%',
                    backgroundColor: colors.light,
                    alignSelf: windowWidth <= 1280 ? 'center' : undefined,
                    maxWidth: 798,
                }}
                type="text"
                label="Buscar producto"
                placeholder="Escribí acá..."
                labelTheme="light"
                theme="dark"
                value={searchProduct}
                onChange={(e) => {

                    if (/^[a-zA-Z0-9\s]*$/.test(e.target.value)) {

                        setSearchProduct(e.currentTarget.value);

                        if (e.currentTarget.value.trim() !== '') {

                            params.delete('p');

                            setProductsCurrentPage(1);

                        }

                        params.set('s', e.currentTarget.value.toString());

                        history.push(`/?${params.toString()}`);

                    }
                }}
                clearButton={
                    searchProduct.trim() !== ''
                        ? <ClearButton
                            onClick={() => {
                                setSearchProduct('')

                                params.delete('s');

                                params.delete('p');

                                setProductsCurrentPage(1);

                                history.push({ search: params.toString() });
                            }}
                        />
                        : <></>
                }
            />

        </MainContainer>

    )
}