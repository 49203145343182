interface Size {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    xxl: string
}

const size: Size = {
    xs: '400px', // for small screen mobile
    sm: '600px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1440px', // for desktop / monitors
    xxl: '1920px', // for big screens
}

export const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
}

export const deviceHorizontal = {
    xs: `(max-height: ${size.xs})`,
    sm: `(max-height: ${size.sm})`,
    md: `(max-height: ${size.md})`,
    lg: `(max-height: ${size.lg})`,
    xl: `(max-height: ${size.xl})`,
    xxl: `(max-height: ${size.xxl})`,
}

interface Font {
    main: string
    secondary: string
    terciary: string
    auxiliar: string
}

const font: Font = {
    main: 'Montserrat',
    secondary: 'Lato',
    terciary: 'Poppins',
    auxiliar: 'sans-serif',
}

export const fonts = {
    main: `${font.main}, ${font.auxiliar}`,
    secondary: `${font.secondary}, ${font.auxiliar}`,
    terciary: `${font.terciary}, ${font.auxiliar}`,
}

interface Color {
    primary: any
    primary_light: string;
    primary_dark: string;
    dark: string;
    grey: string;
    grey_dark: string;
    grey_normal: string;
    grey_light: string;
    light: string;
    border: string;
    danger: string;
    danger_light: string;
    warning: string;
    warning_light: string;
    info: string;
    info_light: string;
    ok: string;
    ok_light: string;
    to_confirm: string;
    extended_event: string;
}

const color: Color = {
    primary: '#FF8300',
    primary_light: '#ffb05c',
    primary_dark: '#4f2900',
    dark: '#111111',
    grey: '#918A8D',
    grey_dark: '#535353',
    grey_normal: '#E7E6E7',
    grey_light: '#FAFAFA',
    light: '#FFFFFF',
    border: '#B7BABC',
    danger: '#C80000',
    danger_light: '#FEDEDE',
    warning: '#c36200',
    warning_light: '#FEEDDE',
    info: '#7ABCF7',
    info_light: '#DEEFFE',
    ok: '#037F4C',
    ok_light: 'rgba(3, 127, 76, 0.08)',
    to_confirm: '#784BD1',
    extended_event: '#5c2e00'
}

export const colors = {
    primary: `${color.primary}`,
    primary_light: `${color.primary_light}`,
    primary_dark: `${color.primary_dark}`,
    dark: `${color.dark}`,
    grey: `${color.grey}`,
    grey_dark: `${color.grey_dark}`,
    grey_normal: `${color.grey_normal}`,
    grey_light: `${color.grey_light}`,
    light: `${color.light}`,
    border: `${color.border}`,
    danger: `${color.danger}`,
    danger_light: `${color.danger_light}`,
    warning: `${color.warning}`,
    warning_light: `${color.warning_light}`,
    info: `${color.info}`,
    info_light: `${color.info_light}`,
    ok: `${color.ok}`,
    ok_light: `${color.ok_light}`,
    to_confirm: `${color.to_confirm}`,
    extended_event: `${color.extended_event}`,
}

